import useSWR from 'swr';

import {useAuthStore} from "../context/useAuthStore";
import { getUserList } from "../../services/prime-users";


const options = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
  refreshInterval: 60000,
};

const apiUserList = ([_, pageNum]: any[]) => getUserList(pageNum);

export const useApiUserList = (pageNum: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized && pageNum ? ['useApiUserList', pageNum] : null, apiUserList, options)
  return {
    data: data || [],
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};


import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useDataStore } from "../../hooks/context/useDataStore";

import { CanvasSkeleton } from "../UI/CanvasSkeleton";
import { AccountTable } from "./AccountTable";

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  progress: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  skeleton: {
    height: '150px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export const Account = (props: any) => {
  const { dataBalance, isRefreshingBalance, refreshBalance } = useDataStore();
  const { currentUser } = useAuthStore();
  const { classes } = useStyles();
  return (
    <>
      {(!dataBalance || dataBalance.length === 0 || !currentUser?.username) && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {(dataBalance?.length > 0) && currentUser?.username && (
        <Paper className={classes.paper}>
          <h2 className={classes.title}>CFX Account</h2>
          <AccountTable data={dataBalance} mutate={refreshBalance} isLoading={isRefreshingBalance} />
        </Paper>
      )}
    </>
  );
}
import { SnackbarProvider } from 'notistack';

import { AuthProvider } from './AuthProvider.cfx';
import { DataProvider } from './DataProvider';

export const ContextProvider = (props: any) => {
  const { children } = props;
  return (
    <SnackbarProvider>
      <AuthProvider>
        <DataProvider>
            {children}
        </DataProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
};

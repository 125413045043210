import { useState, useMemo } from 'react';

import { IconBadgeSml } from "../../../shared/UI/IconBadgeSml";

import EditTokenModal from './EditTokenModal';
import DeleteTokenModal from './DeleteTokenModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()((theme: any) => ({
  table: {
    width: '100%'
  },
  cell: {
    lineHeight: '1rem',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 0.5),
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
}));

const TokenImage = (props: any) => {
  const { data } = props;
  const url = data.tokenImageUrl || data.localImageUrl || data.baseImageUrl;
  return (
    <>
      {url && (<IconBadgeSml mainIcon={url} protocolIcon={data.protocolImageUrl} />)}
    </>
  );
}

const TokenPrice = (props: any) => {
  const { data } = props;
  const price = data.price;
  const priceId = data.priceId;
  return (
    <>
      {price && !priceId && (
        <>
          <span>{price}</span>
        </>
      )}
      {price && priceId && (
        <>
          <span>{price}</span>
          <span>&nbsp;&middot;&nbsp;</span>
          <span>[ {priceId} ]</span>
        </>
      )}
      {!price && priceId && (
        <>
          <span>[ {priceId} ]</span>
        </>
      )}

    </>
  );
}

const TokenStatus = (props: any) => {
  const { data } = props;
  return (
    <>
      {data.status && (<CheckIcon />)}
      {!data.status && (<CrossIcon />)}
    </>
  );
}

export const TokenTable = (props: any) => {
  const { data, isLoading, mutate } = props;

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState<any>();

  const [token, setToken] = useState('');
  const [network, setNetwork] = useState('');
  const [category, setCategory] = useState('');
  const [protocol, setProtocol] = useState('');
  const [symbol, setSymbol] = useState('');
  const [label, setLabel] = useState('');
  const [decimals, setDecimals] = useState('');
  const [price, setPrice] = useState('');

  const tokenData = useMemo(() => {
    if (!token && !category && !network && !protocol && !symbol && !label && !decimals && !price) {
      return data?.sort((a: any, b: any) => a.token?.localeCompare(b.token)).slice(0, 30);
    }
    return data?.filter((x: any) => (
      (!token || (token && (x.token?.includes(token) || x.info.symbol?.includes(token) || x.info.symbol?.includes(token)))) &&
      (!network || (network && x.network?.includes(network))) &&
      (!category || (category && x.category?.includes(category))) &&
      (!protocol || (protocol && x.protocol?.includes(protocol))) &&
      (!symbol || (symbol && x.symbol?.includes(symbol))) &&
      (!label || (label && x.label?.includes(label))) &&
      (!decimals || (decimals && x.decimals?.includes(decimals))) &&
      (!price || (price && (x.price?.includes(price) || x.priceId?.includes(price))))
    )).sort((a: any, b: any) => a.token?.localeCompare(b.token)).slice(0, 30);
  }, [data, token, network, category, protocol, symbol, label, decimals, price]);

  const handleResetFilter = () => {
    setToken('');
    setNetwork('');
    setCategory('');
    setProtocol('');
    setSymbol('');
    setLabel('');
    setDecimals('');
    setPrice('');
  };

  const selectToken = (pk?: string, sk?: string) => {
    setSelectedToken(data?.find((x: any) => x.token === pk && x.sk === sk));
  }

  const handleAddNew = () => {
    console.log('handleAddNew');
    selectToken();
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string, sk: string) => {
    console.log('handleOpenEditModal', id, sk);
    selectToken(id, sk);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string, sk: string) => {
    console.log('handleOpenDeleteModal', id, sk);
    selectToken(id, sk);
    setOpenDeleteModal(true);
  };

  const submitEdit = (id: string, sk: string, token: string, network: string, category: string, protocol: string, label: string, symbol: string, decimals: string, price: string, priceId: string, tokenImageUrl: string, protocolImageUrl: string, status: boolean) => {
    console.log('submitEdit', id, sk);
    setOpenEditModal(false);
    const t = data?.find((x: any) => x.token === id && x.sk === sk);
    if (t) {
      t.token = token;
      t.network = network;
      t.category = category;
      t.protocol = protocol;
      t.label = label;
      t.symbol = symbol;
      t.decimals = decimals;
      t.price = price;
      t.priceId = priceId;
      t.tokenImageUrl = tokenImageUrl;
      t.protocolImageUrl = protocolImageUrl;
      t.status = status;
    }
    else {
      data.push({ token, sk, network, category, protocol, label, symbol, decimals, price, priceId, tokenImageUrl, protocolImageUrl, status, info: { symbol, label } });
    }
    selectToken(id, sk);
    // mutate();
  };

  const submitDelete = (id: string, sk: string) => {
    console.log('submitDelete', id, sk);
    setOpenDeleteModal(false);
    selectToken();
    // mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    selectToken();
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    selectToken();
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><b>Icon</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Token</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Network</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Category</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Protocol</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Symbol</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Name</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Decimals</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Price</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Status</b></TableCell>
            <TableCell className={classes.cell} align="right">
              <IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton>
              <IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell} align="left"></TableCell>
            <TableCell className={classes.cell} align="left"><TextField hiddenLabel variant='outlined' size='small' fullWidth id='token' label='token' value={token} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setToken(e.target.value.trim())} /></TableCell>
            <TableCell className={classes.cell} align="left"><TextField hiddenLabel variant='outlined' size='small' fullWidth id='network' label='network' value={network} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNetwork(e.target.value.trim())} /></TableCell>
            <TableCell className={classes.cell} align="left"><TextField hiddenLabel variant='outlined' size='small' fullWidth id='category' label='category' value={category} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCategory(e.target.value.trim())} /></TableCell>
            <TableCell className={classes.cell} align="left"><TextField hiddenLabel variant='outlined' size='small' fullWidth id='protocol' label='protocol' value={protocol} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProtocol(e.target.value.trim())} /></TableCell>
            <TableCell className={classes.cell} align="left"><TextField hiddenLabel variant='outlined' size='small' fullWidth id='symbol' label='symbol' value={symbol} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSymbol(e.target.value.trim())} /></TableCell>
            <TableCell className={classes.cell} align="left"><TextField hiddenLabel variant='outlined' size='small' fullWidth id='label' label='label' value={label} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)} /></TableCell>
            <TableCell className={classes.cell} align="left"><TextField hiddenLabel variant='outlined' size='small' fullWidth id='decimals' label='decimals' value={decimals} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDecimals(e.target.value.trim())} /></TableCell>
            <TableCell className={classes.cell} align="left"><TextField hiddenLabel variant='outlined' size='small' fullWidth id='price' label='price' value={price} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrice(e.target.value.trim())} /></TableCell>
            <TableCell className={classes.cell} align="center"></TableCell>
            <TableCell className={classes.cell} align="right">
              <IconButton color="primary" onClick={() => handleResetFilter()}><DeleteIcon /></IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tokenData?.map((row: any) => (
            <TableRow key={`${row.token}:${row.network}:${row.label}`} component="tr">
              <TableCell className={classes.cell} align="left" valign='top'><TokenImage data={row} /></TableCell>
              <TableCell className={classes.cell} align="left" valign='top' component="th" scope="row"><b>{row.token}</b><br /><span>{row.info.symbol} - {row.info.label}</span></TableCell>
              <TableCell className={classes.cell} align="left" valign='top'>{row.network}</TableCell>
              <TableCell className={classes.cell} align="left" valign='top'>{row.category}</TableCell>
              <TableCell className={classes.cell} align="left" valign='top'>{row.protocol}</TableCell>
              <TableCell className={classes.cell} align="left" valign='top'>{row.symbol}</TableCell>
              <TableCell className={classes.cell} align="left" valign='top'>{row.label}</TableCell>
              <TableCell className={classes.cell} align="left" valign='top'>{row.decimals}</TableCell>
              <TableCell className={classes.cell} align="left" valign='top'><TokenPrice data={row} /></TableCell>
              <TableCell className={classes.cell} align="center" valign='top'><TokenStatus data={row} /></TableCell>
              <TableCell className={classes.cell} align="right" valign='top' style={{ minWidth: '130px' }}>
                <IconButton onClick={() => handleOpenEditModal(row.token, row.sk)}><EditIcon /></IconButton>
                <IconButton onClick={() => handleOpenDeleteModal(row.token, row.sk)}><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditTokenModal selectedToken={selectedToken} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <DeleteTokenModal selectedToken={selectedToken} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};

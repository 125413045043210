import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";

import { useApiUserList } from '../../hooks/prime/useApiUserList';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';
import { Sort } from '@mui/icons-material';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  symbol: {
    fontWeight: 500
  },
  status: {
    display: 'inline-block',
    minWidth: '70px',
    lineHeight: '1.5rem',
    fontSize: '0.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    borderRadius: 12
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const UserTable = (props: any) => {
  const { data, isLoading, mutate } = useApiUserList('0');
  const navigate = useNavigate();

  const handleUser = (userId: string) => {
    navigate(`/user/${userId}`);
  };

  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell} align="center"><span className={classes.heading}>ID</span></TableCell>
          <TableCell className={classes.cell} align="left"><span className={classes.heading}>Name</span></TableCell>
          <TableCell className={classes.cell} align="left"><span className={classes.heading}>Email</span></TableCell>
          <TableCell className={classes.cell} align="left"><span className={classes.heading}>Updated</span></TableCell>
          <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
            {isLoading && (
              <IconButton><CircularProgress color="primary" size={24} /></IconButton>
            )}
            {!isLoading && (
              <IconButton color="primary" disabled={isLoading} onClick={() => mutate()}><RefreshIcon /></IconButton>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.sort((a: any, b: any) =>  a.name.localeCompare(b.name)).map((row: any) => (
          <TableRow component="tr" key={row.tradeId} className={classes.row}>
            <TableCell className={classes.cell} align="center">{row.userId}</TableCell>
            <TableCell className={classes.cell} align="left"><b>{row.name}</b></TableCell>
            <TableCell className={classes.cell} align="left">{row.email}</TableCell>
            <TableCell className={classes.cell} align="left">{format(new Date(row.updatedAt), 'dd-MMM-yyyy HH:mm')}</TableCell>
            <TableCell className={classes.cell} align="right">
              <Button variant="outlined" color="primary" disableElevation onClick={() => handleUser(row.userId)} startIcon={<LaunchIcon />}>view</Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

import React, { createContext, useState } from "react";
import { addHours } from "date-fns";

import { useAuthStore } from "../hooks/context/useAuthStore";
import { useApiUsersOnline } from "../hooks/cfx/useApiUsersOnline";
import { useApiBalancePortfolio } from "../hooks/cfx/useApiBalancePortfolio";
import { useApiTradeOffers } from "../hooks/cfx/useApiTradeOffers";
import { useApiTradeList } from "../hooks/cfx/useApiTradeList";

import { refreshBalancePortfolio, WaitForBalance } from "../services/cfx-balance";

const REFRESH_TIMEOUT = 5000; // 5 secs

interface IDataContext {
  dataUsersOnline: any[]; isLoadingUsersOnline: boolean; mutateUsersOnline: () => void;
  dataBalance: any[]; isLoadingBalance: boolean; mutateBalance: () => void; refreshBalance: () => void; isRefreshingBalance: boolean;  
  dataOfferMine: any[]; isLoadingOfferMine: boolean; mutateOfferMine: () => void;
  dataOfferOpen: any[]; isLoadingOfferOpen: boolean; mutateOfferOpen: () => void; refreshOfferOpen: () => void;
  dataTradeOpen: any[]; isLoadingTradeOpen: boolean; mutateTradeOpen: () => void;
  dataTradeRecent: any[]; isLoadingTradeRecent: boolean; mutateTradeRecent: () => void; refreshTradeRecent: () => void;
  dataTradeFinished: any[]; isLoadingTradeFinished: boolean; mutateTradeFinished: () => void;
};

const initialState: IDataContext = {
  dataUsersOnline: [], isLoadingUsersOnline: false, mutateUsersOnline: () => { },
  dataBalance: [], isLoadingBalance: false, mutateBalance: () => { }, refreshBalance: () => { }, isRefreshingBalance: false, 
  dataOfferMine: [], isLoadingOfferMine: false, mutateOfferMine: () => { },
  dataOfferOpen: [], isLoadingOfferOpen: false, mutateOfferOpen: () => { }, refreshOfferOpen: () => { },
  dataTradeOpen: [], isLoadingTradeOpen: false, mutateTradeOpen: () => { },
  dataTradeRecent: [], isLoadingTradeRecent: false, mutateTradeRecent: () => { }, refreshTradeRecent: () => { },
  dataTradeFinished: [], isLoadingTradeFinished: false, mutateTradeFinished: () => { },
};

export const DataContext = createContext<IDataContext>(initialState);

export const DataProvider = ({ children }: { children: React.ReactElement }) => {
  const { isAuthorized } = useAuthStore();

  const [offerDateFrom, setOfferDateFrom] = useState(new Date().toISOString());
  const [tradeDateFrom, setTradeDateFrom] = useState(addHours(new Date(), -1).toISOString());

  const { data: dataUsersOnline, isLoading: isLoadingUsersOnline, mutate: mutateUsersOnline } = useApiUsersOnline();
  const { data: dataBalance, isLoading: isLoadingBalance, mutate: mutateBalance } = useApiBalancePortfolio();
  const { data: dataOfferMine, isLoading: isLoadingOfferMine, mutate: mutateOfferMine } = useApiTradeOffers('mine', '2000-01-01T00:00:00.000Z');
  const { data: dataOfferOpen, isLoading: isLoadingOfferOpen, mutate: mutateOfferOpen } = useApiTradeOffers('open', offerDateFrom);
  const { data: dataTradeOpen, isLoading: isLoadingTradeOpen, mutate: mutateTradeOpen } = useApiTradeList('open', tradeDateFrom, '');
  const { data: dataTradeRecent, isLoading: isLoadingTradeRecent, mutate: mutateTradeRecent } = useApiTradeList('recent', tradeDateFrom, '');
  const { data: dataTradeFinished, isLoading: isLoadingTradeFinished, mutate: mutateTradeFinished } = useApiTradeList('finished', '2000-01-01T00:00:00Z', '');

  const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);

  const refreshBalance = () => {
    if (!isRefreshingBalance) {
      setIsRefreshingBalance(true);
      refreshBalancePortfolio().then(() => {
        WaitForBalance()
          .then((wait: boolean) => mutateBalance())
          .catch((e: any) => console.log(e))
          .finally(() => setIsRefreshingBalance(false));
      });
    }
  };
  
  const refreshOfferOpen = () => {
    setOfferDateFrom(new Date().toISOString());
    mutateOfferOpen();
  };

  const refreshTradeRecent = () => {
    setTradeDateFrom(addHours(new Date(), -1).toISOString());
    mutateTradeRecent();
  };
  const value = {
    dataUsersOnline, isLoadingUsersOnline, mutateUsersOnline,
    dataBalance, isLoadingBalance, mutateBalance, refreshBalance, isRefreshingBalance, 
    dataOfferMine, isLoadingOfferMine, mutateOfferMine,
    dataOfferOpen, isLoadingOfferOpen, mutateOfferOpen, refreshOfferOpen,
    dataTradeOpen, isLoadingTradeOpen, mutateTradeOpen,
    dataTradeRecent, isLoadingTradeRecent, mutateTradeRecent, refreshTradeRecent,
    dataTradeFinished, isLoadingTradeFinished, mutateTradeFinished,
  };
  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};
import { api } from "./api-connect";

const request = api('admin');

export const getTransactionUsers = async () => {
  return await request(`/get_transactions_users`);
}

export const getBalance = async (starkkey: string) => {
  return await request(`/get_balance?stark_key=${starkkey}`);
}


import { useEffect } from 'react';

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useNavigate } from "react-router-dom";

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { UserTable } from '../../shared/User/UserTable';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
}));

export const Users = () => {
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Client Manager</h1>
      </div>
      <Paper className={classes.paper}>
        <UserTable />
      </Paper>
    </AppLayout>
  );
};

import { useApiUserProfile } from '../../hooks/prime/useApiUserProfile';
import { CanvasSkeleton } from "../../shared/UI/CanvasSkeleton";
import { Avatar } from '../../shared/Picture/Avatar';
import { Profile } from '../../shared/User/Profile';
import { Account } from '../../shared/User/Account';
import { Stats } from '../../shared/User/Stats';
import { PrimeBalance } from '../../shared/User/PrimeBalance';

import { makeStyles } from 'tss-react/mui';
import { Paper, Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  grid: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  detail: {
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
  verified: {
    color: theme.palette.success.dark,
  },
  picture: {
    width: '100%',
    height: 'auto',
    borderRadius: '50%',
  },
  skeleton: {
    height: '150px',
    marginBottom: theme.spacing(3),
  }
}));

export const UserProfile = (props: any) => {
  const { userId } = props;
  const { data } = useApiUserProfile(userId);
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      {!(data && data.updatedAt) && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {(data && data.updatedAt) && (
        <Grid container justifyContent="flex-start" spacing={3}>
          <Grid item lg={3} md={6} sm={12} xs={12}><Paper className={classes.paper}><Avatar size="large" picture={data.picture} /></Paper></Grid>
          <Grid item lg={9} md={6} sm={12} xs={12}>

            <Grid container justifyContent="flex-start" spacing={3} className={classes.grid}>
              <Grid item lg={7} md={12} sm={12} xs={12}>
                <Profile data={data} />
              </Grid>
              <Grid item lg={5} md={12} sm={12} xs={12}>
                <Stats userId={userId} />
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" spacing={3} className={classes.grid}>
              <Grid item lg={5} md={12} sm={12} xs={12}>
                <PrimeBalance userId={userId} />
              </Grid>
              <Grid item lg={7} md={12} sm={12} xs={12}>
                <Account userId={userId} />
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      )}
    </div>
  );
}
import { useState } from 'react';

import EditAddressModal from './EditAddressModal';
import DeleteAddressModal from './DeleteAddressModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';

const useStyles = makeStyles()((theme: any) => ({
  table: {
    width: '100%'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  },
}));

const displaySources = (sources: any[]) => sources?.map((source: any) => `${source.name} ${source.networks ? `: ${source.networks}` : ''} ${source.minutes ? `( every: ${source.minutes} mins )` : ''}`).join('<br />');

export const AddressTable = (props: any) => {
  const { data, isLoading, mutate } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>();

  const selectAddress = (id?: string) => {
    const address = data?.find((x: any) => x.address === id);
    setSelectedAddress(address);
  }

  const handleAddNew = () => {
    console.log('handleAddNew');
    selectAddress();
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string) => {
    console.log('handleOpenEditModal', id);
    selectAddress(id);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log('handleOpenDeleteModal', id);
    selectAddress(id);
    setOpenDeleteModal(true);
  };

  const submitEdit = (id: string) => {
    console.log('submitEdit', id);
    setOpenEditModal(false);
    selectAddress(id);
    mutate();
  };

  const submitDelete = (id: string) => {
    console.log('submitDelete', id);
    setOpenDeleteModal(false);
    selectAddress();
    mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    selectAddress();
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    selectAddress();
  };

  const { classes } = useStyles();
  return (
    <>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><b>Address</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Description</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Sources</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Status</b></TableCell>
            <TableCell className={classes.cell} align="right"><IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((row: any) => (
            <TableRow key={row.address} component="tr">
              <TableCell className={classes.cell} align="left" component="th" scope="row"><h5>{row.address}</h5></TableCell>
              <TableCell className={classes.cell} align="left">{row.description}</TableCell>
              <TableCell className={classes.cell} align="left"><span dangerouslySetInnerHTML={{ __html: displaySources(row.sources) }}></span></TableCell>
              <TableCell className={classes.cell} align="left">{row.status}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
                <IconButton onClick={() => handleOpenEditModal(row.address)}><EditIcon /></IconButton>
                <IconButton onClick={() => handleOpenDeleteModal(row.address)}><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditAddressModal selectedAddress={selectedAddress} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <DeleteAddressModal selectedAddress={selectedAddress} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};

import { useAuthStore } from "../../hooks/context/useAuthStore";

import { useApiTradeStats } from "../../hooks/cfx/useApiTradeStats";

import { StatsTable } from "./StatsTable";
import { CanvasSkeleton } from "../UI/CanvasSkeleton";

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(2.5, 3, 3, 3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  progress: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  skeleton: {
    height: '150px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export const Stats = (props: any) => {
  const { data, isLoading, mutate } = useApiTradeStats();
  const { currentUser } = useAuthStore();
  const { classes } = useStyles();
  return (
    <>
      {(!data || !currentUser?.username) && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {data && currentUser?.username && (
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between">
            <Grid item textAlign="left"><h2 className={classes.title}>Stats</h2></Grid>
            <Grid item textAlign="right">
              {isLoading && (
                <IconButton><CircularProgress color="primary" size={24} /></IconButton>
              )}
              {!isLoading && (
                <IconButton color="primary" disabled={isLoading} onClick={() => mutate()}><RefreshIcon /></IconButton>
              )}
            </Grid>
          </Grid>

          <StatsTable data={data} mutate={mutate} isLoading={isLoading} />
        </Paper>
      )}
    </>
  );
}

import { useState } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import EditSecretModal from './EditSecretModal';
import DeleteSecretModal from './DeleteSecretModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: '#f58e4e33',
  }
}));

const SecretTable = (props: any) => {
  const { data, isLoading, mutate } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedSecret, setSelectedSecret] = useState('');

  const selectSecret = (id?: string) => {
    props.onSelect(id || '');
    setSelectedSecret(id || '');
  }

  const handleAddNew = () => {
    console.log('handleAddNew');
    selectSecret();
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string) => {
    console.log('handleOpenEditModal', id);
    selectSecret(data?.find((x: any) => x.secretId === id));
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log('handleOpenDeleteModal', id);
    selectSecret(data?.find((x: any) => x.secretId === id));
    setOpenDeleteModal(true);
  };

  const submitEdit = (id: string) => {
    console.log('submitEdit', id);
    setOpenEditModal(false);
    selectSecret(id);
    mutate();
  };

  const submitDelete = (id: string) => {
    console.log('submitDelete', id);
    setOpenDeleteModal(false);
    selectSecret();
    mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    selectSecret();
    mutate();
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    selectSecret();
    mutate();
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><b>ID</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Name</b></TableCell>
            <TableCell className={classes.cell} align="left"><b>Description</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Created</b></TableCell>
            <TableCell className={classes.cell} align="center"><b>Updated</b></TableCell>
            <TableCell className={classes.cell} align="right"><IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((row: any) => (
            <TableRow key={row.secretId} component="tr" onClick={() => props.onSelect(row.secretId)} className={clsx(classes.row, { [classes.selected]: (props.selectedSecretId === row.secretId) })}>
              <TableCell className={classes.cell} align="left">{row.secretId}</TableCell>
              <TableCell className={classes.cell} align="left" component="th" scope="row"><b>{row.name}</b></TableCell>
              <TableCell className={classes.cell} align="left">{row.description}</TableCell>
              <TableCell className={classes.cell} align="center">{format(new Date(row.createdAt), 'dd-MMM-yyyy, H:mm a')}</TableCell>
              <TableCell className={classes.cell} align="center">{format(new Date(row.updatedAt), 'dd-MMM-yyyy, H:mm a')}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
                <IconButton onClick={() => handleOpenEditModal(row.secretId)}><EditIcon /></IconButton>
                <IconButton onClick={() => handleOpenDeleteModal(row.secretId)}><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditSecretModal secret={selectedSecret} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <DeleteSecretModal secret={selectedSecret} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};
export default SecretTable;
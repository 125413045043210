import { useApiBalance } from '../../../../hooks/prime/useApiBalance';

import { Wallets } from './Wallets';
import { Networks } from './Networks';
import { Categories } from './Categories';
import { GrandTotal } from './GrandTotal';

export const PrimeBalances = (props: any) => {
  const { userId } = props;

  const { data, isLoading, mutate } = useApiBalance(userId);
  console.log('PrimeBalances', data);
  return (
    <>
      {data && (
        <>
          <Wallets userId={userId} />
          <Networks networks={data.networksTotal} />
          <Categories categories={data.categories} />
          <GrandTotal totalBalanceUSD={data.totalBalanceUSD} />
        </>
      )}
    </>
  );
}
import { useMemo } from 'react';

import { useApiTransactionUsers } from '../../../../hooks/connect/useApiTransactionUsers';
import { useApiUserTransactions } from '../../../../hooks/connect/useApiUserTransactions';
import { TransactionsTable } from './TransactionsTable';

export const ConnectTransactions = () => {
  const { data: users, isLoading, mutate } = useApiTransactionUsers();
  console.log('ConnectTransactions', users);
  const data = useMemo(()=> { 
    const results = users?.map((user: any) => useApiUserTransactions(user.starkkey));
    return results?.map((result: any) => result.data).flat().sort((a: any, b: any) => a - b);
  }, [users]);
  return (
    <TransactionsTable data={data} isLoading={isLoading} mutate={mutate} />
  );
}
import useSWR from 'swr';
import { getOffchainAssets } from "../../services/prime-admin";
import { useAuthStore } from "..//context/useAuthStore";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiOffchainAssets = ([_, walletId]:  [string, string]) => getOffchainAssets(walletId);

export const useApiOffchainAssets = (walletId: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiOffchainAssets', walletId] : null, apiOffchainAssets, options);
  return {
    data: walletId && data?.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};

import { format } from "date-fns";

import { makeStyles } from 'tss-react/mui';
import { Paper, Grid } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: '720px'
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  detail: {
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
  verified: {
    color: theme.palette.success.dark,
  },
  picture: {
    width: '100%',
    height: 'auto',
    borderRadius: '50%',
  },
  skeleton: {
    height: '150px',
    marginBottom: theme.spacing(3),
  }
}));

export const Profile = (props: any) => {
  const { data } = props;
  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container className={classes.grid} >
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs={5} className={classes.itemTitle} style={{ marginBottom: '3px' }}>username: </Grid><Grid item xs={7} className={classes.itemValue} style={{ fontSize: '1.2rem', fontWeight: 500 }}>{data.username}</Grid>
            <Grid item xs={5} className={classes.itemTitle}>name: </Grid><Grid item xs={7} className={classes.itemValue}>{data.name}</Grid>
            <Grid item xs={5} className={classes.itemTitle}>email: </Grid><Grid item xs={7} className={classes.itemValue}>{data.email}</Grid>
            <Grid item xs={5} className={classes.itemTitle}>last updated: </Grid><Grid item xs={7} className={classes.itemValue}>{format(new Date(data.updatedAt), 'dd-MMM-yyyy h:mm a')}</Grid>
            <Grid item xs={5} className={classes.itemTitle} style={{ marginBottom: '6px' }}>verified: </Grid><Grid item xs={7} className={classes.itemValue}>
              {(data.isEmailVerified) && (
                <div className={classes.verified}><CheckCircleOutlineRoundedIcon /></div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
import { api } from "./api-prime";

const request = api('wallets');

export const getBalance = async (userId: string) => {
  // console.log('getBalance', userId);
  return await request(`balances?userId=${userId}`);
};

export const getNFTs = async (userId: string) => {
  console.log('getNFTs', userId);
  return await request(`nfts?userId=${userId}`);
};

export const getTransactions = async (userId: string) => {
  console.log('getTransactions', userId);
  return await request(`transactions?userId=${userId}`);
};
import { useState, useEffect } from 'react';
import { createSecret, updateSecret } from "../../../../services/prime-admin";

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const getNameValue = (name: string) => {
  const titlecase = name.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1));
  const nospace = titlecase.replace(/[^a-zA-Z0-9]+/g, '-');
  const prefix = nospace.replace(/^[-]+$/g, '');
  const trailing = prefix.replace(/[-]+$/g, '');
  return trailing;
}

const getSecretValue = (apiKey: string, apiSecret: string) => {
  return JSON.stringify({ apiKey, privateKey: apiSecret });
};

const useStyles = makeStyles()(theme => ({
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

const EditSecretModal = (props: any) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');

  const [errorName, setErrorName] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [errorApiKey, setErrorApiKey] = useState('');
  const [errorApiSecret, setErrorApiSecret] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    if (props.secret) {
      setName(props.secret.name);
      setDescription(props.secret.description);
      setApiKey('');
      setApiSecret('');
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.secret])

  const clearForm = () => {
    setName('');
    setDescription('');
    setApiKey('');
    setApiSecret('');
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorName('');
    setErrorDescription('');
    setErrorApiKey('');
    setErrorApiSecret('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (name?.length === 0) {
      setErrorName('Enter the secret name.');
      return false;
    }
    if (apiKey?.length === 0) {
      setErrorName('Enter the api key.');
      return false;
    }
    if (apiSecret?.length === 0) {
      setErrorName('Enter the api private secret.');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.secret) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating secret: <strong>${name}</strong> ... ` });
    createSecret(getNameValue(name), description.trim(), getSecretValue(apiKey, apiSecret)).then((data) => {
      console.log('CreateSecret', data);
      if (data) {
        setMessage({ type: 'success', description: `Secret: <strong>${name}</strong> created <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        clearErrors();
        clearForm();
        props.handleSubmit();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating secret details: <strong>${name}</strong> ... ` });
    updateSecret(props.secret?.secretId, getNameValue(name), description.trim(), getSecretValue(apiKey, apiSecret)).then((data) => {
      console.log('UpdateSecret', data);
      if (data) {
        setMessage({ type: 'success', description: `Secret: <strong>${name}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit();
        clearErrors();
        clearForm();
      }
    }).catch(e => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.secret && (
        <DialogTitle id='form-dialog-title'>Update Fireblocks API Secret</DialogTitle>
      )}
      {!props.secret && (
        <DialogTitle id='form-dialog-title'>Create a new Fireblocks API Secret</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.secret && (
          <DialogContentText>Update the secret details:</DialogContentText>
        )}
        {!props.secret && (
          <DialogContentText>Input the secret details:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <TextField
            id='name'
            label='name'
            value={name}
            error={errorName.length > 0}
            helperText={errorName}
            onChange={e => setName(e.target.value.trim())}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
            fullWidth
            autoFocus
          />
          <TextField
            id='description'
            label='description'
            value={description}
            error={errorDescription.length > 0}
            helperText={errorDescription}
            onChange={e => setDescription(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
            fullWidth
          />
          <TextField
            id='apiKey'
            label='API key'
            value={apiKey}
            error={errorApiKey.length > 0}
            helperText={errorApiKey}
            onChange={e => setApiKey(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
            fullWidth
          />
          <TextField
            id='apiSecret'
            label='API Private Key'
            value={apiSecret}
            error={errorApiSecret.length > 0}
            helperText={errorApiSecret}
            onChange={e => setApiSecret(e.target.value)}
            margin='normal'
            variant='outlined'
            multiline
            minRows={10}
            maxRows={10}
            InputProps={{ style: { fontWeight: 300, fontSize: '0.8rem' } }}
            InputLabelProps={{ shrink: true, style: { fontWeight: 700 } }}
            fullWidth
          />
          {message && message.description &&
            <Alert
              variant='outlined'
              severity={message.type as any}
              className={classes.alert}
              action={isLoading ? <CircularProgress color="secondary" size={20} /> : <></>}
            >
              <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
            </Alert>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary'>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disableElevation disabled={isLoading}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditSecretModal;
import { format } from 'date-fns';
import { useAuthStore } from "../../hooks/context/useAuthStore";

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme) => ({
  root: {

  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
}));

export const StatsTable = (props: any) => {
  const { currentUser } = useAuthStore();
  const { classes } = useStyles();
  return (
    <>
      {currentUser && props.data && (
        <Grid container spacing={1} className={classes.grid}>
          <Grid item xs={6} className={classes.itemTitle}>Total Trades:</Grid><Grid item xs={6} className={classes.itemValue}>{props.data.numTrades}</Grid>
          <Grid item xs={6} className={classes.itemTitle}>Total Traded (AUST):</Grid><Grid item xs={6} className={classes.itemValue}>{props.data.totalTraded.eaud.toFixed(2)}</Grid>
          <Grid item xs={6} className={classes.itemTitle}>Total Traded (USDC):</Grid><Grid item xs={6} className={classes.itemValue}>{props.data.totalTraded.usdc.toFixed(2)}</Grid>
          <Grid item xs={6} className={classes.itemTitle}></Grid><Grid item xs={6} className={classes.itemValue}></Grid>
          <Grid item xs={6} className={classes.itemTitle}>Maximum Trade Amount:</Grid><Grid item xs={6} className={classes.itemValue}>{props.data.compliance.maxUserTotal} AUST</Grid>
          <Grid item xs={6} className={classes.itemTitle}>Maximum Trades Allowed:</Grid><Grid item xs={6} className={classes.itemValue}>{props.data.compliance.currentUserRate} / {props.data.compliance.maxUserRate}</Grid>
          <Grid item xs={6} className={classes.itemTitle}>Next Reset:</Grid><Grid item xs={6} className={classes.itemValue}>{format(new Date(props.data.compliance.rateResetAt), "dd-MMM-yyyy HH:mm:ss")}</Grid>
        </Grid>
      )}
    </>
  );
};

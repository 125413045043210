import React, { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

import { useAuthStore } from "../hooks/context/useAuthStore";
import { useDataStore } from "../hooks/context/useDataStore";

import { Button } from '@mui/material';

const OPEN_TRADE_STATES = ['CREATED', 'ENQUEUED', 'PENDING', 'PROCESSING'];

interface INotifyContext {
  newTrades: any[];
};

const initialState: INotifyContext = {
  newTrades: []
};

export const NotifyContext = createContext<INotifyContext>(initialState);

export const NotifyProvider = ({ children }: { children: React.ReactElement }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { currentUser } = useAuthStore();
  const { dataTradeOpen, mutateTradeRecent } = useDataStore();
  const navigate = useNavigate();

  const [newTrades, setNewTrades] = useState<any[]>([]);

  const handleTrade =  useCallback((tradeId: string) => {
    console.log('tradeId', tradeId);
    if (window.location.pathname === '/trade') {
      navigate(`#${tradeId}`);
    } else {
      navigate(`/trade#${tradeId}`);
    }
  }, [navigate]);

  const notify = useCallback((trade: any) => {
    console.log('notify', trade);
    const action = (snackbarId: any) => (<Button variant="contained" color='info' size="small" onClick={() => { closeSnackbar(snackbarId); handleTrade(trade.tradeId); }}> Trade </Button>);
    if (trade.userId === currentUser.userId) {
      enqueueSnackbar(`New Trade Request: Sell ${trade.amount} ${trade.symbolSend} ( ${trade.rate} )`, { action, anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    } else {
      enqueueSnackbar(`New Trade: Buy ${trade.amount} ${trade.symbolSend} ( ${trade.rate} )`, { action, anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    }
  }, [currentUser, closeSnackbar, enqueueSnackbar, handleTrade]);

  useEffect(() => {
    const newNotify = newTrades.filter((x: any) => !x.isNotify);
    if (newNotify.length > 0) {
      newNotify.map((x: any) => notify(x));
      const data = newTrades.map((x: any) => Object.assign({}, x, { isNotify: true }));
      setNewTrades(data);
    }
  }, [newTrades, notify]);

  useEffect(() => {
    if (dataTradeOpen?.length > 0) {
      const newtrades = dataTradeOpen.filter((r: any) => OPEN_TRADE_STATES.includes(r.state) && !newTrades.find((x: any) => x.tradeId === r.tradeId));
      if (newtrades.length > 0) {
        const newdata = newtrades.map((x: any) => Object.assign({}, x, { isNotify: false }));
        const data = [...newTrades, ...newdata];
        setNewTrades(data);
        mutateTradeRecent();
      }
    }
  }, [dataTradeOpen, newTrades, mutateTradeRecent]);

  const value = { newTrades };
  return (
    <NotifyContext.Provider value={value}>
      {children}
    </NotifyContext.Provider>
  );
  
};
import { api } from "./api-prime";

const request = api('admin');

export const getUserList = async (pageNum: string) => {
  return await request(`users?page=${pageNum}&limit=100`);
};

export const getUserProfile = async (userId: string) => {
  return await request(`user/${userId}`);
};
import useSWR from 'swr';

import { useAuthStore } from "../context/useAuthStore";
import { getTransactionUsers } from "../../services/connect-admin";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const apiTransactionUsers = ([_]: [string]) => getTransactionUsers();

export const useApiTransactionUsers = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['apiTransactionUsers'] : null, apiTransactionUsers, options)
  return {
    data: data || [],
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
}
